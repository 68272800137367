import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What's your OS?"
    options={[
      {
        label: "macOS",
        link: "/app/tool/sketch/"
      },
      {
        label: "Windows",
        link: "/app/tool/adobe-xd/"
      },
      {
        label: "Linux",
        link: "/app/tool/figma/"
      },
      {
        label: "Chrome OS",
        link: "/app/tool/figma/"
      }
  ]}/>
)

export default Picker;


